import { ConfigTheme } from '../../_core/types/theme/ConfigTheme.type';
import howItWorks from './how-it-works.seeer';
import about from './about.seeer';
import home from './home.seeer';
import general from './general.seeer';
import policies from './policies.seeer';
import auth from './auth.seeer';

const theme: ConfigTheme = {
  howItWorks,
  about,
  home,
  general,
  policies,
  auth,
};

export default theme;
